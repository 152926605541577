
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { getProductCategoriesRequest } from "@/sea/apis/ProductCategoriesService";
import { UserUtils } from "@/sea/utils/UserUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import Datatable from "@/sea/components/datatables/Datatable.vue";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "productCategories",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();

    const name = ref<string | null>(null);
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);

    name.value = route.query.name ? `${route.query.name}` : null;
    pageNumber.value = Number(route.query.pageNumber) || 1;
    pageSize.value = Number(route.query.pageSize) || 10;

    const loading = ref(false);

    const generateTableHeaders = () => {
      return [
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.productCategories.tblHeaderName"
          ),
          key: "name",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.productCategories.tblHeaderDate"
          ),
          key: "date",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.productCategories.tblHeaderActions"
          ),
          key: "actions",
        },
      ];
    };

    const tableHeaders = ref(generateTableHeaders());

    watch(locale, () => {
      tableHeaders.value = generateTableHeaders();
    });

    const {
      productCategoriesRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      getProductCategories,
    } = getProductCategoriesRequest();

    const updateQueries = async () => {
      const newQuery = Object.assign({}, route.query);
      if (name.value) {
        newQuery.name = name.value;
      } else {
        delete newQuery.name;
      }
      if (pageNumber.value) newQuery.pageNumber = pageNumber.value.toString();
      if (pageSize.value) newQuery.pageSize = pageSize.value.toString();
      await router.replace({ query: newQuery });
    };

    const getData = async () => {
      loading.value = true;

      await updateQueries();
      await getProductCategories(
        name.value,
        sort.value,
        pageNumber.value,
        pageSize.value
      );
      reinitializeComponents();

      loading.value = false;
    };

    const sortProductCategories = async (order) => {
      if (order) {
        sort.value = `${order.columnName},${order.order}`;
        await getData();
      }
    };

    const updatePageSize = async (sizePerPage) => {
      pageSize.value = sizePerPage;
      await getData();
    };

    const updatePageNumber = async (numberOfPage) => {
      pageNumber.value = numberOfPage;
      await getData();
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.productCategories.title", [
        "sea.aside.categories.product.title",
      ]);
      await getData();
    });

    return {
      I18nUtils,
      UserUtils,
      DateTimeUtils,
      name,
      tableHeaders,
      getData,
      loading,
      productCategoriesRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      sortProductCategories,
      updatePageSize,
      updatePageNumber,
    };
  },
});
